import { Field, Formik } from "formik";
import { getService } from "react-in-angularjs";
import { ICreateMap, IMap } from "../../types/IMap";
import { classNameMapper } from "../../utils/classNameMapper";
import { ICollection } from "../../types/ICollection";
import { useAppSelector } from "../../hooks/useAppSelector";
import React, { useMemo, useState } from "react";
import { Button } from "../../design/Button";
import { react2angular } from "react2angular";
import PropTypes from "prop-types";
import { withReduxProvider } from "../../services/withReduxProvider";
import { IHasMapAccess } from "../../../app/routes/mapping/services/has-map-access";
import { useGetMapActionTypesQuery, useGetMapActionsQuery } from "../../slices/apiSlice";
import { MapFormActions } from "./MapFormActions";
import { IWorkspace } from "../../types/IWorkspace";
import { getSiblingMaps } from "../../data/workspace";

interface IEditMapFormProps {
  map?: IMap | ICreateMap;
  parents?: IMap[];
  workspace: IWorkspace;
  parentChangeAllowed: boolean;
  onSave: (map: Partial<IMap> | ICreateMap) => Promise<void>;
  onCancel: () => void;
  isCreating: boolean;
}

const EditMapFormComponent: React.FC<IEditMapFormProps & JSX.IntrinsicAttributes> = ({
  onCancel,
  map,
  onSave,
  parents,
  parentChangeAllowed,
  isCreating,
  workspace,
}: IEditMapFormProps) => {
  const intervals = getService("Interval").getAdverbs(["One-Time"]);
  const hasAccess: IHasMapAccess = getService("hasMapAccess");

  const isEditing = map && "ID" in map && map.ID;
  const { availableCollections } = useAppSelector((state) => state.pages.truterritory);
  const collections =
    map?.collectionIDs.map((id) => availableCollections.find((c) => c.ID == id)).filter((c): c is ICollection => !!c) ||
    [];
  const { data: initialActions = [] } = useGetMapActionsQuery(isEditing ? map.ID : 0, { skip: !isEditing });
  const { data: actionTypes = [] } = useGetMapActionTypesQuery(collections.map((c) => c.ID) || [], {
    skip: !map?.collectionIDs?.length,
  });

  const { entity } = useAppSelector((state) => state.app);

  const entityReportsEnabled = useMemo(() => !!entity?.permissions["map-site-reports"], [entity]);
  const actions = useMemo(() => [...initialActions].sort((a, b) => a.sorder - b.sorder), [initialActions]);
  const [errorMessage, setErrorMessage] = useState("");

  if (!map) return <></>;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: map.name,
        dateEnabled: map.dateEnabled,
        compEnabled: map.compEnabled,
        reportsEnabled: map.reportsEnabled,
        reportsCollectionID: map.reportsCollectionID,
        sliderType: map.sliderType,
        sliderInterval: map.sliderInterval,
        parentID: map.parentID,
        allowCollaboration: map.allowCollaboration,
        selectable: { ...(map.selectable || {}) },
        editable: { ...(map.editable || {}) },
        actions: actions,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const newMap: Partial<IMap> = { ...map };

        setSubmitting(true);
        newMap.name = values.name;
        newMap.parentID = values.parentID || null;
        if (hasAccess("update")) {
          newMap.dateEnabled = values.dateEnabled;
          newMap.compEnabled = values.compEnabled;

          newMap.reportsEnabled = values.reportsEnabled;
          newMap.reportsCollectionID = values.reportsCollectionID;
          newMap.sliderType = values.sliderType;
          newMap.sliderInterval = values.sliderInterval;
          newMap.allowCollaboration = values.allowCollaboration;
          newMap.selectable = values.selectable;
          newMap.editable = values.editable;
          newMap.actions = values.actions;
        } else {
          // Maps created by collaborators will have the "allowCollaboration" flag enabled automatically.
          newMap.allowCollaboration = hasAccess("collaborate") && !hasAccess("update");
        }

        // Sort it to the bottom of the list, either of children under the parent or top-level maps.
        if (isCreating || newMap.parentID != map.parentID) {
          newMap.sorder = newMap.parentID
            ? getSiblingMaps(workspace, newMap.parentID, newMap.ID).length
            : parents?.length || 0;
        }

        try {
          await onSave(newMap);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          setErrorMessage(e?.data?.status?.message || "Unexpected error");
        }
      }}
    >
      {({ values, handleChange, setFieldValue, handleSubmit, isSubmitting, errors, touched }) => (
        <>
          <form className="form-horizontal" onSubmit={handleSubmit}>
            <div className="modal-header col-md-12">
              <h4>{!isCreating ? "Edit" : "Create"} Map</h4>
            </div>
            <div className="modal-body col-md-12">
              {isCreating && hasAccess("collaborate") && !hasAccess("update") && (
                <p>
                  The current map does not allow modifications. To save your changes as a new map, please enter a name
                  for your map.
                </p>
              )}
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
              <div className="form-group">
                <label className="control-label col-md-3" htmlFor="name">
                  Name
                </label>
                <div className="col-md-6">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {hasAccess("update") && (
                <>
                  <div className="form-group">
                    <div className="col-md-offset-2 col-md-9">
                      <input
                        type="checkbox"
                        checked={values.dateEnabled}
                        id="dateEnabled"
                        name="dateEnabled"
                        onChange={({ target }) => setFieldValue(target.name, target.checked)}
                        className={classNameMapper({ _checked: values.dateEnabled ?? false })}
                      />
                      <label className="checkbox" htmlFor="dateEnabled">
                        Map contains time data
                      </label>
                    </div>
                    <div className="col-md-offset-2 col-md-9">
                      <input
                        type="checkbox"
                        checked={values.compEnabled}
                        id="compEnabled"
                        name="compEnabled"
                        onChange={({ target }) => setFieldValue(target.name, target.checked)}
                        className={classNameMapper({ _checked: values.compEnabled ?? false })}
                        disabled={!map.dateEnabled}
                      />
                      <label className="checkbox" htmlFor="compEnabled">
                        Enable comparisons
                      </label>
                    </div>
                    <div className="col-md-offset-2 col-md-9">
                      <input
                        type="checkbox"
                        checked={values.reportsEnabled}
                        id="reportsEnabled"
                        name="reportsEnabled"
                        onChange={({ target }) => setFieldValue(target.name, target.checked)}
                        className={classNameMapper({ _checked: values.reportsEnabled ?? false })}
                        disabled={!entityReportsEnabled}
                      />
                      <label className="checkbox" htmlFor="reportsEnabled">
                        Enable site reports
                      </label>
                    </div>
                    <div className="col-md-offset-2 col-md-9">
                      <input
                        type="checkbox"
                        checked={values.allowCollaboration}
                        id="allowCollaboration"
                        name="allowCollaboration"
                        onChange={({ target }) => setFieldValue(target.name, target.checked)}
                        className={classNameMapper({ _checked: values.allowCollaboration ?? false })}
                      />
                      <label className="checkbox" htmlFor="allowCollaboration">
                        Allow collaboration
                      </label>
                    </div>
                  </div>
                  <div className={classNameMapper({ "form-group": true, disabled: !values.reportsEnabled })}>
                    <label className="control-label col-md-3">Reports collection</label>
                    <div className="select-holder col-md-9 form-control">
                      <select
                        value={values.reportsCollectionID ?? undefined}
                        name="reportsCollectionID"
                        onChange={handleChange}
                        disabled={!values.reportsEnabled}
                      >
                        <option value="">Select a layer</option>
                        {collections.map((collection) => (
                          <option key={collection?.ID} value={collection?.ID}>
                            {collection?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className={classNameMapper({ "form-group": true, disabled: !values.dateEnabled })}>
                    <label className="control-label col-md-3" htmlFor="sliderType">
                      Slider type
                    </label>
                    <div className="select-holder col-md-9 form-control">
                      <select
                        id="sliderType"
                        name="sliderType"
                        value={values.sliderType ?? undefined}
                        onChange={handleChange}
                        disabled={!values.dateEnabled}
                      >
                        <option value=""></option>
                        <option value={1}>One-point slider</option>
                        <option value={2}>Two-point slider</option>
                      </select>
                    </div>
                  </div>
                  <div className={classNameMapper({ "form-group": true, disabled: !values.dateEnabled })}>
                    <label className="control-label col-md-3" htmlFor="sliderInterval">
                      Slider interval
                    </label>
                    <div className="select-holder col-md-10 form-control">
                      <select
                        value={values.sliderInterval ?? undefined}
                        name="sliderInterval"
                        onChange={handleChange}
                        disabled={!values.dateEnabled}
                      >
                        <option value=""></option>
                        {Object.keys(intervals).map((key) => (
                          <option key={key} value={key}>
                            {intervals[key]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
              {parentChangeAllowed && (
                <div className="form-group">
                  <label htmlFor="parentID" className="control-label col-md-3">
                    Parent Map
                  </label>
                  <div className="select-holder col-md-10 form-control">
                    <select value={values.parentID ?? undefined} name="parentID" onChange={handleChange}>
                      <option value="">(none)</option>
                      {parents
                        ?.filter((p) => !("ID" in map) || p.ID != map.ID)
                        .map((p) => (
                          <option key={p.ID} value={p.ID}>
                            {p.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}
              {hasAccess("update") && (
                <>
                  <div className="form-group">
                    <label className="control-label col-md-3">Collection controls</label>
                    <div className="col-md-9">
                      <table className="collection-control-options">
                        <thead>
                          <tr>
                            <th>Selectable</th>
                            <th>Editable</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {collections.map((collection) => (
                            <tr key={collection.ID}>
                              <td>
                                <Field
                                  type="checkbox"
                                  name={`selectable[${collection.ID}]`}
                                  id={`selectable${collection.ID}`}
                                  className={classNameMapper({ _checked: values.selectable[collection.ID] ?? false })}
                                />
                                <label className="checkbox" htmlFor={`selectable${collection.ID}`}></label>
                              </td>
                              <td>
                                {(collection.type == "master" || collection.type == "writable") && (
                                  <>
                                    <Field
                                      type="checkbox"
                                      name={`editable[${collection.ID}]`}
                                      id={`editable${collection.ID}`}
                                      className={classNameMapper({ _checked: values.editable[collection.ID] ?? false })}
                                    />
                                    <label className="checkbox" htmlFor={`editable${collection.ID}`}></label>
                                  </>
                                )}
                              </td>
                              <td>{collection.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div>
                    <h3>Selection Actions</h3>
                    <MapFormActions
                      collections={collections}
                      actions={values.actions}
                      actionTypes={actionTypes}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    ></MapFormActions>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer">
              <div className="controls">
                {isSubmitting ? (
                  <Button type="submit" variant="light-blue" disabled>
                    Saving...
                  </Button>
                ) : (
                  <Button type="submit" variant="light-blue">
                    Save
                  </Button>
                )}
                <Button variant="plain" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

export const EditMapForm = withReduxProvider(EditMapFormComponent);

EditMapForm.propTypes = {
  map: PropTypes.any,
  parents: PropTypes.any,
  workspace: PropTypes.any,
  parentChangeAllowed: PropTypes.any,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
};

export const AngularEditMapForm = react2angular(EditMapForm);
